<template>
<div class="content-wrapper">

  <div v-if="isList" class="content pt-0">
    <bo-page-title />
    <div class="card">
      <div class="bg-white card-header">
        <div class="row align-items-center">
          <div class="col-md-4 col-lg-5">
            <div class="form-row align-items-center">
              <div class="col-lg-4">
                <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
              </div>
              <div class="col-lg-8">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-md-6 col-lg-3">
            <b-form-group class="mb-0">
                <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_ranap"  :options="Config.mr.statusRanap" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-md-8 col-lg-9">
            <div class="row gx-1">
              <div class="col-md-auto">
                <div class="form-group mb-0">
                  <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                </div>
              </div>
              <div class="col-md">
                <div class="row g-1">

                  <div class="col-md-6 col-lg-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan" :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6 col-lg-2">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk" :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6 col-lg-3">
                    <b-form-group class="mb-0">
                      <v-select :disabled="user.levelId != 1" placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.ranap_bangsal" :options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6 col-lg-3">
                    <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp" :options="mDPJPUtama" :clearable="true" label="text" :reduce="v=>v.value"></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>NO.</th>
              <th>NO. KEDATANGAN</th>
              <th>TANGGAL MASUK</th>
              <th>NAMA/NO KTP</th>
              <th>DOKTER DPJP</th>
              <th>NO RM</th>
              <th>RUANGAN</th>
              <th>JENIS RAWAT INAP</th>
              <th>TANGGAL LAHIR</th>
              <th>USIA</th>
              <th>PPJP</th>
              <th>STATUS</th>
              <th style="min-width: 100px;" class="text-center">AKSI</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k" :class="(validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_perawat != 'Y') ? 'table-warning':''">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td>
                  <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                </td>
                <td>
                  <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                </td>
                <td>
                  <div>
                    <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                    <br>
                    {{v.ap_code||"-"}}</div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                  <div v-if="v.aranr_is_meninggal == 'Y'">
                    <template v-if="menuRole('RanapFormulirLainnyaPerawat')">
                      <b-badge style="cursor: pointer;" variant="warning" @click="isiFormPenyebabKematian(v.aranr_id)" v-b-tooltip.hover.noninteractive="'Click untuk mengisi Form Penyebab Kematian'">MENINGGAL</b-badge>
                    </template>
                    <template v-else>
                      <b-badge variant="warning">MENINGGAL</b-badge>
                    </template>
                  </div>
                </td>
                <td>{{v.bu_full_name||"-"}}</td>
                <td>
                  <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                  <span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
                  <div class="text-success-700 font-weight-semibold">
                    {{v.no_reg||"-"}}
                  </div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                  <span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>
                </td>
                <td>
                  <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                </td>
                <td>
                  <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                  <span v-else> - </span>
                </td>
                <td>
                  <div class="font-weight-semibold">
                    {{v.ap_usia_with_ket || "-"}}
                  </div>
                </td>
                <td>
                  {{v.nama_ppjp||"-"}}
                </td>
                <td>
                  <div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan PPJP</div>
                  <div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan Perawat Pelaksana</div>
                  <template v-else>
                    <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>

                    <template v-if="v.aranr_status == 1">
                      <div v-if="v.aranr_is_done_perawat != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Perawat</div>

                      <div v-if="v.aranr_is_done_dokter != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Dokter</div>
                    </template>

                  </template>

                  <div v-if="validationTime(v.aranr_reg_date) && v.aranr_have_kajian_awal_perawat != 'Y'" v-b-tooltip.hover="'Anda telah melewati batas waktu pengisian Kajian Awal Medis (1x24 jam)'" class="badge badge-danger mb-1">Batas Waktu Kajian Awal</div>
                </td>
                <td>
                  <a href="javascript:;" v-if="((user.id == v.aranr_ppjp || user.levelId == 1)) && moduleRole('tugaskan_ppjp')" @click="tugasKanPPJP(v)" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800" v-b-tooltip.hover.right :title="v.aranr_perawat_pelaksana ? 'Ganti Perawat pelaksana' : 'Tugaskan Perawat pelaksana'">
                    <i class="fa-solid fa-user-nurse"></i>
                  </a>

                  <a href="javascript:;" v-if="moduleRole('tandatangani_transfer_intra') && v.aranres_cara_masuk != 'BBL'" @click="ttdTransferIntraUGDRANAP(v)" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right title="Tandatangani Form Transfer Intra">
                    <i class="icon-pencil4"></i>
                  </a>

                  <a href="javascript:;" v-if="v.aranr_is_ttd_intra == 'Y' && moduleRole('tandatangani_transfer_intra')" @click="checkTTDIntra(v)" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right title="Tandatangani Form Transfer Intra">
                    <i class="icon-pencil4"></i>
                  </a>

                  <a href="javascript:;" @click="doOpenRiwayatKajian(v)" v-if="moduleRole('lihat_dokumen_kajian')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" data-popup="tooltip" v-b-tooltip.hover.right title="Lihat Dokumen Kajian">
                  <i class="icon-file-text2"></i>
                  </a>

                  <a href="javascript:;" @click.prevent="openModalFormConsent(v.aranr_id)" v-if="v.aranr_show_inform_consent_dpjp == 'Y' && moduleRole('form_inform_consent')" class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800" data-popup="tooltip" v-b-tooltip.hover.right.noninteractive :title="v.aranr_is_submit_inform_consent_dpjp == 'Y' ? 'Ubah Inform Consent' : 'Isi Inform Consent'">
                    <i class="fas fa-notes-medical"></i>
                  </a>

                  <!-- -->
                  <a href="javascript:;" @click.prevent="openModalInformConsentTTD(v.aranr_id,v)" v-if="v.aranr_is_submit_inform_consent_dpjp == 'Y' &&  moduleRole('ttd_form_inform_consent')" class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800" data-popup="tooltip" v-b-tooltip.hover.right title="Tindak Lanjuti Inform Consent">
                    <i class="fas fa-notes-medical"></i>
                  </a>

                  <template v-if="(user.id == v.aranr_ppjp || user.levelId == 1)">
                    <!-- kajian perawat -->
                    <template v-if="moduleRole('buat_kajian')">
                      <a href="javascript:;" v-if="v.aranr_have_kajian_awal_perawat != 'Y'" @click="initKajianAwal(v)" class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800" v-b-tooltip.hover.right title="Tambahkan Kajian Awal Keperawatan">
                        <i class="fas fa-notes-medical"></i>
                      </a>

                      <a href="javascript:;" v-if="v.aranr_have_kajian_awal_perawat == 'Y'" @click="lihatKajian(v)" class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800" v-b-tooltip.hover.right :title="v.isEdit ? 'Edit Kajian Awal Keperawatan' : 'Isi Kajian Awal Keperawatan'">
                        <i class="icon-pencil7"></i>
                      </a>
                    </template>
                    <!-- kajian perawat -->
                  </template>

                  <template v-if="moduleRole('discharge_planning') && v.aranr_is_done_perawat === 'Y'">
                    <!-- v-if="v.aranr_is_done_criteria_discharge_plan == 'N'" -->
                    <template>
                      <a href="javascript:;" @click="$router.push({ name: 'DischargePlanningCriteria', params: { pageSlug: v.aranr_id } })" class="btn btn-icon rounded-round  btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right 
                      :title="v.aranr_is_done_criteria_discharge_plan == 'N' ? 'Isi Kriteria Discharge Planning' : 'Edit Isi Kriteria Discharge Planning'">

                        <i class="fa-solid fa-notes-medical"></i>
                      </a>
                    </template>
                  </template>

                  <a v-if="moduleRole('riwayat_dpjp_pindah_ruang')" href="javascript:;" @click="doOpenRiwayat(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" v-b-tooltip.hover.right title="Riwayat DPJP Pasien">
                    <i class="fa-solid fa-users"></i>
                  </a>

                  <a href="javascript:;" v-if="moduleRole('kajian_persalinan') && v.mranjr_code == 'OB'" @click="openModalKajianPersalinan(v)" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover.right title="Kajian Persalinan">
                    <i class="fa-solid fa-person-pregnant"></i>
                  </a>

                </td>

              </tr>
            </template>
          </tbody>
          <tbody v-if="data.data.length == 0">
            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
          <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-left"></i>
          </a>
          <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <validation-observer ref="VFormSubmitPerawatPelaksana">
      <b-modal v-model="openPerawatPelaksana" @ok.prevent="submitPerawatPelaksana" size="lg" no-close-on-backdrop class="text-center" :title="'Tugaskan Perawat Pelaksana'">
        <table class="table table-sm table-bordered">
          <thead>
            <tr>
              <th colspan="6">Informasi Pasien</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{rowEdit.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Tanggal Lahir / Umur</h4>
                  <p>{{rowEdit.ap_dob | moment("DD MMMM YYYY") }} / {{rowEdit.ap_usia}} Tahun</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Kelamin</h4>
                  <p>{{rowEdit.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Ruangan</h4>
                  <p>{{rowEdit.mranb_name||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Kamar / No. Tempat Tidur / Kelas</h4>
                  <p>{{rowEdit.mranbed_name||"-"}} / {{rowEdit.mrankel_name||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Kelas Titipan</h4>
                  <p>-</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Jenis Cara Bayar</h4>
                  <p>{{getConfigDynamic(Config.mr.statusJaminan,rowEdit.aranres_jaminan)||"-"}}</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Alergi</h4>
                  <ul v-if="(rowEdit.alergi||[]).length" class="pl-3">
                    <li v-for="(v,k) in (rowEdit.alergi||[])" :key="k+'alergi'">
                      {{v.name||"-"}} - <span class="font-weight-semibold">{{v.jenis||"-"}}</span>
                    </li>
                  </ul>
                  <span v-else>Tidak ada Alergi</span>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Diagnosa Masuk</h4>
                  <p>{{rowEdit.diagnosa||"-"}}</p>
                </div>
              </td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th colspan="6">Penugasan Perawat Pelaksana</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td colspan="3" width="40%">
                <div>
                  <label for="selPppjp">Pilih Perawat Pelaksana</label>
                  <v-select placeholder=" -- Pilih Perawat Pelaksana -- " class="vs__multiple" v-model="rowEdit.aranr_perawat_pelaksana" multiple :clearable="true" :options="mPPJP" label="text" :reduce="v=>v.value">
                    <template slot="selected-option" slot-scope="option">
                      <span>{{ option.text }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span>{{ option.text }} - menangani
                        <span class="font-weight-semibold"> {{option.pasien}} Pasien </span></span>
                    </template>
                  </v-select>
                </div>
                <VValidate :name="'Perawat Pelaksana'" v-model="rowEdit.aranr_perawat_pelaksana" :rules="{required : 1}" />
              </td>
              <td colspan="3" width="50%">
                <div>
                  <label for="selPppjp">PPJP</label>
                  <p class="font-weight-semibold">{{rowEdit.nama_ppjp||"-"}}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
    </validation-observer>

    <b-modal id="kajianPersalinan" size="md" centered title="Kajian Persalinan" hide-footer>
      <div class="row">
        <div class="col-md-6 mb-2 position-relative">
          <router-link class="btn_cta_persalinan" :to="{ name: 'RanapKajianMandiriSC', params: { pageSlug: dataModal.aranr_id } }"> 
            Form Kajian Mandiri SC
          </router-link>

          <a href="javascript:;" class="cta-float btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.top title="Unduh Kajian Mandiri" @click="downloadKajianMandiri(dataModal)" v-if="dataModal.isVerifKajianMandiriSC == 'Y'">
            <i class="icon-download"></i>
          </a>
        </div>
        <div class="col-md-6">
          <router-link class="btn_cta_persalinan" :to="{ name: 'RanapLaporanNifas', params: { pageSlug: dataModal.aranr_id } }"> Form Laporan Nifas</router-link>
        </div>

        <div class="col-md-6">
          <router-link class="btn_cta_persalinan" :to="{ name: 'RanapCatatanPersalinan', params: { pageSlug: dataModal.aranr_id } }"> Form Catatan Persalinan</router-link>
        </div>

        <div class="col-md-6">
          <router-link class="btn_cta_persalinan" :to="{ name: 'RanapLaporanPersalinan', params: { pageSlug: dataModal.aranr_id } }"> Form Laporan Persalinan</router-link>
        </div>
      </div>
    </b-modal>

    <b-modal id="verifikasiKajianPersalinan" size="lg" centered title="Verifikasi Kajian Persalinan" hide-footer>
      <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0" v-if="showTabKajianPersalinan">
        <b-tab title="Kajian Persalinan">
          <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0" v-if="showTabKajianPersalinan">
            <b-tab title="Kajian Mandiri" v-if="!emptyKajianMandiri">
              <KajianMandiri :data="kajianPersalinanData.kajianMandiri" />
            </b-tab>
            <b-tab title="Catatan Persalinan" v-if="!emptyCatatanPersalinan">
              <CatatanPersalinan :data="kajianPersalinanData.catatanPersalinan" />
            </b-tab>
            <b-tab title="Laporan Persalinan" v-if="!emptyLaporanPersalinan">
              <LaporanPersalinan :data="kajianPersalinanData.laporanPersalinan" />
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>

      <validation-observer ref="VFormVerifikasiKajianPersalinan">
        <b-form @submit.prevent="doVerifyKajianPersalinan()">
            <label class="font-weight-bold mt-3">
                Verifikasi Kajian Persalinan
            </label>
            <b-form-group>
                <b-form-checkbox-group
                    v-model="dataModal.verify"
                    name="flavour-2"
                >
                    <template v-if="kajianPersalinanData.isVerifKajianMandiriSC == 'Y'">
                        <i class="icon-checkmark2 text-success"></i> Kajian Mandiri SC
                    </template>
                    <b-form-checkbox value="KAJIAN_MANDIRI_SC" v-else>
                        Kajian Mandiri SC
                    </b-form-checkbox>

                    <template v-if="kajianPersalinanData.isVerifCatatanPersalinan == 'Y'">
                        <i class="icon-checkmark2 text-success"></i> Catatan Persalinan
                    </template>
                    <b-form-checkbox value="CATATAN_PERSALINAN" v-else>Catatan Persalinan</b-form-checkbox>

                    <template v-if="kajianPersalinanData.isVerifLaporanPersalinan == 'Y'">
                        <i class="icon-checkmark2 text-success"></i> Laporan Persalinan
                    </template>
                    <b-form-checkbox value="LAPORAN_PERSALINAN" v-else>Laporan Persalinan</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>

            <div class="text-right">
                <button class="btn btn-labeled btn-labeled-left btn-primary" type="submit">
                    <span>Submit</span>
                    <b><i class="icon-paperplane"></i></b>
                </button>
            </div>
        </b-form>
      </validation-observer>


    </b-modal>

    <b-modal size="xl" title="Tanda Tangan Form Transfer Intra" id="ttd_transfer_intra" hide-footer>
      <b-overlay :show="loadingTTDIntra">
        <validation-observer ref="VFormTTD" v-slot="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(doSubmitTTD)">  
            <div class="form-underlying card-body p-3">
              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra1">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                        <h2>S</h2>
                      </div>
                      <strong class="ml-2">Situation</strong>
                    </div>
                  </a>
                </div>
                <div class="wrap_line collapse show" id="formIntra1">
                  <table class="table table-bordered">
                    <tr>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>Nama</h4>
                          <p>{{dataModal.ap_fullname||"-"}}</p>
                        </div>
                      </td>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>No. Rekam Medis</h4>
                          <p>{{dataModal.ap_code||"-"}}</p>
                        </div>
                      </td>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>NIK</h4>
                          <p>{{dataModal.ap_nik||"-"}}</p>
                        </div>
                      </td>
                    </tr>
    
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Lahir</h4>
                          <p v-if="dataModal.ap_dob">{{dataModal.ap_dob | moment("DD MMMM YYYY")}}</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>DPJP</h4>
                          <p>{{dataModal.dpjp_name||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Datang</h4>
                          {{ dataModal.artipr_tanggal_datang | moment('DD MMMM YYYY, HH:mm') }}
                        </div>
                      </td>
                    </tr>
    
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Diagnosa</h4>
                          <p>{{dataModal.artipr_diagnosa||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Kondisi Saat Ini</h4>
                          {{ dataModal.artipr_kondisi_saat_ini }}
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4></h4>
                          <p></p>
                        </div>
                      </td>
                    </tr>
    
                    <tr>
                      <td>
                        <div class="result_tab">
                        <h4>Dari Ruang</h4>
                        <p>{{dataModal.ruang_dari||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Ke Ruang</h4>
                          <p>{{dataModal.ruang_ke||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Pindah</h4>
                          {{ dataModal.artipr_tanggal_pindah | moment('DD MMMM YYYY, HH:mm')}}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra2">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                          <h2>B</h2>
                      </div>
                      <strong class="ml-2">Background</strong>
                    </div>
                  </a>
                </div>
                <div class="wrap_line collapse show" id="formIntra2">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for=""><strong>Keluhan Utama</strong></label>
                            <p>{{ dataModal.artipr_keluhan_utama }}</p>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for=""><strong>Riwayat Penyakit</strong></label>
                            <p>{{ dataModal.artipr_riwayat_penyakit }}</p>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for=""><strong>Intervensi yang Telah Dilakukan</strong></label>
                            <p>{{ dataModal.artipr_background_intervensi }}</p>
                          </div>  
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="asuhanPembedahan">Riwayat Pembedahan </label>
                            <P>{{ dataModal.artipr_riwayat_pembedahan }}</P>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for=""><strong>Obat-obatan yang Diberikan</strong></label>
                            <p>{{ dataModal.artipr_obat_obatan_yang_diberikan }}</p>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for=""><strong>Cairan Parenteral yang Diberikan</strong></label>
                            <p>{{ dataModal.artipr_cairan_parental_yang_diberikan }}</p>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for=""><strong>Riwayat Alergi</strong></label>
                            <p>{{ dataModal.artipr_riwayat_alergi }}</p>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for=""><strong>Tindakan Invasif</strong></label>
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th width="48">#</th>
                              <th>Tindakan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (dataModal.artipr_tindakan_invasif||[])" :key="k">
                              <td>{{k+1}}</td>
                              <td>
                                {{ dataModal.artipr_tindakan_invasif[k] }}
                              </td>
                            </tr>
                            <tr v-if="!(dataModal.artipr_tindakan_invasif||[]).length">
                              <td colspan="3" class="text-center">
                                  Tidak ada Data
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra3">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                        <h2>A</h2>
                      </div>
                      <strong class="ml-2">Assessment</strong>
                    </div>
                  </a>
                </div>

                <div class="wrap_line collapse show" id="formIntra3">
                  <div class="card border shadow-0">
                    <div class="bg-light card-header py-2">
                      <h6 class="card-title mb-0 font-weight-semibold">Kondisi Pasien Terkini</h6>
                    </div>
                    <div class="card-body">
                      <table class="table table-bordered">
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Skala Nyeri</h4>
                              <p>{{dataModal.artipr_skala_nyeri_value||0}} 
                                <span v-if="dataModal.artipr_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                <span v-else-if="dataModal.artipr_skala_nyeri_value >= 1 && dataModal.artipr_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                                <span v-else-if="dataModal.artipr_skala_nyeri_value >= 4 && dataModal.artipr_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                <br />(Numeric Rating Scale)
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tingkat Kesadaran</h4>
                              {{ dataModal.artipr_tingkat_kesadaran_value }}
                              <p>
                                {{ dataModal.artipr_tingkat_kesadaran_value }}
                                <template v-if="dataModal.umur_hari <= 28">
                                  <span v-if="dataModal.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 12 && dataModal.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 10 && dataModal.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 7 && dataModal.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 5 && dataModal.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 4 && dataModal.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </template>
                                <template v-else-if="dataModal.umur_hari > 28">
                                  <span v-if="dataModal.artipr_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmetris</span> 
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 12 && dataModal.artipr_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 10 && dataModal.artipr_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 7 && dataModal.artipr_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somnolen</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 5 && dataModal.artipr_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else-if="dataModal.artipr_tingkat_kesadaran_value >= 4 && dataModal.artipr_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                  <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </template>
                                <br />(GCS)
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Risiko Jatuh</h4>
                              <p>{{dataModal.artipr_resiko_jatuh_value||0}} 
                                <span v-if="dataModal.artipr_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                <span v-else-if="dataModal.artipr_resiko_jatuh_value >= 7 && dataModal.artipr_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                <span v-else-if="dataModal.artipr_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                <br />
                                <span v-if="dataModal.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                                <span v-else-if="dataModal.ap_usia > 18 && dataModal.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                                <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tekanan Darah</h4>
                              <p>{{dataModal.artipr_tekanan_darah_min||"-"}}/{{dataModal.artipr_tekanan_darah_max||"-"}} mmHG</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Nadi</h4>
                              <p>{{dataModal.artipr_nadi||"-"}}x per menit</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Suhu</h4>
                              <p>{{dataModal.artipr_suhu||"-"}} &deg;C</p>
                              </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Respiration Rate</h4>
                              <p>{{dataModal.artipr_respiration||"-"}}x per menit</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>SpO2</h4>
                              <p>{{dataModal.artipr_spo2||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4></h4>
                              <p></p>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                          <label for=""><strong>Pemeriksaan Lab</strong></label>
                          <p>{{ dataModal.artipr_lab }}</p>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group">
                          <label for=""><strong>Radiologi</strong></label>
                          <p>{{ dataModal.artipr_radiologi }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header p-0" role="tab">
                  <a href="javascript:;" class="btn btn-block" data-toggle="collapse" data-target="#formIntra4">
                    <div class="d-flex align-items-center">
                      <div class="label_code m-0">
                        <h2>R</h2>
                      </div>
                      <strong class="ml-2">Recommendation</strong>
                    </div>
                  </a>
                </div>
                <div class="wrap_line collapse show" id="formIntra4">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Tindakan yang Perlu Dilanjutkan</label>
                        <p>{{ dataModal.artipr_tindakan }}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Usulan Kolaborasi</label>
                        <p>{{ dataModal.artipr_usulan_kolab }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Penandatangan 1</label>
                      <b-form-input v-model="dataModal.artipr_ttd_name_1"></b-form-input>
                      <VValidate name="Nama Penandatangan" v-model="dataModal.artipr_ttd_name_1" :vid="'nama_ttd' + 1" rules="required" />
                    </b-form-group>
                    <div class="form-group">
                      <div class="signing-element" data-target-id="ttd1">
                        <VueSignaturePad  
                          class="sign-container"
                          width="100%"
                          height="160px"
                          ref="ttd1"
                        />
                        <div class="mt-2">
                          <button type="button" @click="undoTTD('ttd1','artipr_ttd_1', 'dataModal')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <label>Penandatangan 2</label>
                    <b-form-group>
                      <b-form-input v-model="dataModal.artipr_ttd_name_2"></b-form-input>
                      <VValidate name="Nama Penandatangan" v-model="dataModal.artipr_ttd_name_2" :vid="'nama_ttd' + 2" rules="required" />
                    </b-form-group>
                    <div class="form-group">
                      <div class="signing-element" data-target-id="ttd2">
                        <VueSignaturePad  
                          class="sign-container"
                          width="100%"
                          height="160px"
                          ref="ttd2"
                        />
                        <div class="mt-2">
                          <button type="button" @click="undoTTD('ttd2','artipr_ttd_2', 'dataModal')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                            <b><i class="icon-cross3"></i></b>
                            <span>Clear</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-row class="mt-3">
                <b-col md=12 class="text-right">
                  <b-button type="button" class="mr-2" variant="secondary" @click="closeModalTTD">Tutup</b-button>
                  <b-button type="button" variant="primary" @click="doSubmitTTD">Simpan</b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <b-modal id="list_ttd_intra" title="Pilih Tanda Tangan Form Transfer Intra" hide-footer>
        <div class="row">
            <div class="col-md-6 mb-2" v-if="dataModalCheck.show_ttd_intra_ranap == 'Y'"><a href="javascript:;" @click.prevent="ttdTransferIntra(dataModal)" class="btn_cta_persalinan"> Form Tranfser Intra Ranap </a></div>
            <div class="col-md-6 mb-2" v-if="dataModalCheck.transferBedah"><a href="javascript:;" @click.prevent="TTDBedahRanap(dataModalCheck.transferBedah)" class="btn_cta_persalinan"> Form Tranfser Intra Bedah Ranap </a></div>
        </div>
    </b-modal>

    <b-modal id="ttd_bedah_ranap" size="xl" title="Tanda Tangan Form Bedah Ke Ranap" hide-footer>
        <ModalTTDBedahRanap v-if="dataModal.arantbr_id" :arano_id="dataModal.arano_id" :arantbr_id="dataModal.arantbr_id" @closeModal="closeModalTTDBedahRanap" @successSubmit="successTTDBedahRanap" @resize="resize" />
    </b-modal>

  </div>

  <b-modal size="xl" id="modal_inform_consent" no-close-on-backdrop hide-footer title="Form Inform Consent">
    <validation-observer ref="VFormConsent" v-slot="{handleSubmit}">
        <b-overlay :show="loadingConsent">
            <b-form @submit.prevent="handleSubmit(doSubmitConsent)">
                <b-tabs v-model="tabIndex" nav-class="nav-tabs-bottom nav-justified mt-2 mb-2">
                    <b-tab 
                    v-for="(value, index) in dataModal.forms"
                    :key="index"
                    :title="value.aranic_name"
                    :active="index == 0"
                    >
                        <table class="table table-bordered table-sm table-input">
                            <thead>
                                <tr>
                                    <td colspan="3">
                                        <div class="result_tab">
                                            <h4>Nama Tindakan</h4>
                                            <p>{{dataModal.forms[index].aranic_name}}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="24">No.</th>
                                    <th>Jenis Informasi</th>
                                    <th>Isi Informasi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_diagnosis" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Dasar Diagnosis</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_dasar_diagnosis" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Tindakan Kedokteran</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_tindakan_dokter" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Indikasi Tindakan</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_indikasi_tindakan" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Tata Cara</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_tata_cara" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Tujuan</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_tujuan" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Risiko</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_risiko" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Komplikasi</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_kompilasi" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Prognosis</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_prognosis" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Alternatif & Risiko</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_alternatif" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Lain-lain</td>
                                    <td class="input-cell">
                                    <textarea v-model="dataModal.forms[index].aranic_lainnya" @input="autoSave" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td width="50%" colspan="2">
                                    <div>
                                        <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                                        <div>
                                        <b-form-radio-group :options="Config.mr.pasienSetuju" v-model="dataModal.forms[index].aranic_is_pasien_setuju" @input="autoSave" />
                                        <VValidate :vid="'setuju_pasien' + index" :name="`Persetujuan Pasien`" v-model="dataModal.forms[index].aranic_is_pasien_setuju" :rules="{required:1}" />
                                        </div>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div>
                                        <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                                        <div>
                                        <b-form-radio-group :options="Config.mr.persetujuanTindakan" v-model="dataModal.forms[index].aranic_persetujuan_tindakan" @input="autoSave" />
                                        <VValidate :vid="'setuju_diberikan' + index" :name="`Persetujuan Tindakan Kepada`" v-model="dataModal.forms[index].aranic_persetujuan_tindakan" :rules="{required:1}" />
                                        </div>
                                    </div>
                                    </td>
                                </tr>
                                </tfoot>
                        </table>
                    </b-tab>
                </b-tabs>
                <div class="text-right mt-3">
                    <button type="button" class="btn" @click="closeModalInformCosent">Batal</button>
                    <button type="button" @click="doSubmitConsent" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </b-form>
        </b-overlay>
    </validation-observer>
</b-modal>

  <b-modal id="ttd_modal_inform_consent" no-close-on-backdrop size="xl" title="Inform Consent">
    <b-row>
      <div class="col-md-12 col-12 mb-2" v-if="(dataModal.forms||[]).length">
        <b-form-group class="mb-0">
          <label>Dokter Pelaksana</label>
          <v-select placeholder=" -- Pilih Dokter Pelaksana -- " v-model="dataModal.dokter_pelaksana"
            :options="mDPJPUtama" label="text" :reduce="v=>v.text"></v-select>
        </b-form-group>
      </div>

      <b-col md="4" v-for="(v, index) in dataModal.forms" :key="index">
        <div class="wrap_line mb-0 h-100">
          <div class="mb-2">
            <h3 class="pb-0">{{v.aranic_name||"-"}}</h3>
          </div>
          <div>
            <a href="javascript:;" @click.prevent="openModalUnduhInformConsent(v)" class="btn alpha-success border-success text-success-800 mb-1">
              <b><i class="icon-download"></i></b>
              <span class="ml-2">Unduh Template Dokumen</span>
            </a>
            <Uploader @input="uploadDokStatus(v,k)" v-model="v.aranic_file" isDocument type="doc_only" />
          </div>

          <div class="d-flex align-items-center justify-content-between mt-2">
            <span v-if="v.aranic_status == 'DONE' || v.aranic_file" class="badge badge-success">Selesai</span>
            <span v-else class="badge badge-info">Unggah TTD</span>

            <div>
              <a v-if="v.aranic_file" :href="uploader(v.aranic_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>

              <a href="javascript:;" @click="openModalInformConsentDetail(v)" data-toggle="modal" class="btn btn-sm alpha-info border-info btn-icon rounded-round text-info-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-eye"></i></a>
            </div>
          </div>

          <span :class="v.aranic_is_pasien_setuju == 'Y' ? 'badge badge-success':'badge badge-danger'">
          {{v.aranic_is_pasien_setuju == 'Y' ? 'Tindakan Disetujui':'Tindakan Ditolak'}} Oleh 
          {{v.aranic_persetujuan_tindakan == 'P' ? 'Pasien':'Penanggung Jawab'}}
          </span>
        </div>
      </b-col>
    </b-row>
  </b-modal>

  <b-modal id="unduh_doc_inform_consent" size="lg" title="Unduh Template Dokumen" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template">
    <validation-observer ref="VFormDocInformConsent" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmitUnduhDocInformConsent)">
        <table class="table table-bordered table-sm text-uppercase">
          <tbody>
            <tr>
              <td colspan="3" class="bg-primary">Data Pasien</td>
            </tr>
            <tr>
              <td width="33%">
                <div class="result_tab">
                  <h4>Nama Pasien</h4>
                  <p>{{rowIC.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Usia</h4>
                  <p>{{rowIC.ap_usia_with_ket||"-"}}</p>
                </div>
              </td>
              <td width="33%">
                <div class="result_tab">
                  <h4>Alamat</h4>
                  <p>{{rowIC.ap_address||"-"}}</p>
                </div>
              </td>
            </tr>
            <!-- == 'PJ'-->
            <template v-if="rowIC.aranic_persetujuan_tindakan == 'PJ'">
              <tr>
                <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.aranic_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control" placeholder="Nama Penanggung Jawab" />
                    <VValidate name="Nama Penanggung Jawab" v-model="rowIC.aranic_nama_pj" :rules="{required: 1}" />
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Usia Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.aranic_usia_pj" type="text" name="alamat" id="alamat" class="form-control" placeholder="Usia Penanggung Jawab" />
                    <VValidate name="Usia Penanggung Jawab" v-model="rowIC.aranic_usia_pj" :rules="{required: 1}" />
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Alamat Penanggung Jawab</h4>
                    <b-form-input v-model="rowIC.aranic_address_pj" type="text" name="alamat" id="alamat" class="form-control" placeholder="Alamat Penanggung Jawab" />
                    <VValidate name="Alamat Penanggung Jawab" v-model="rowIC.aranic_address_pj" :rules="{required: 1}" />
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </b-form>
    </validation-observer>
  </b-modal>

  <b-modal size="xl" id="modal_inform_consent_detail" title="Detail Inform Consent" ok-only>
    <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-2">
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan</h4>
                <p>{{detailInformConsent.aranic_name}}</p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
              {{ detailInformConsent.aranic_diagnosis }}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
              {{ detailInformConsent.aranic_dasar_diagnosis }}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
              {{ detailInformConsent.aranic_tindakan_dokter }}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
              {{ detailInformConsent.aranic_indikasi_tindakan }}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
              {{ detailInformConsent.aranic_tata_cara }}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
              {{ detailInformConsent.aranic_tujuan }}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
              {{ detailInformConsent.aranic_risiko }}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
              {{ detailInformConsent.aranic_kompilasi }}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
              {{ detailInformConsent.aranic_prognosis }}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
              {{ detailInformConsent.aranic_alternatif }}
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
              {{ detailInformConsent.aranic_lainnya }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td width="50%" colspan="2">
              <div>
                <label for="">Apakah pasien/pendamping pasien menyetujui tindakan?</label>
                <div>
                  <strong>{{ detailInformConsent.aranic_is_pasien_setuju == 'Y' ? 'Tindakan Disetujui' : 'Tindakan Ditolak' }}</strong>
                </div>
              </div>
            </td>
            <td width="50%">
              <div>
                <label for="">Persetujuan tindakan diberikan kepada</label>
                <div>
                  <strong>{{ detailInformConsent.aranic_persetujuan_tindakan == 'P' ? 'Pasien' : 'Penanggungjawab Pasien' }}</strong>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-tabs>
  </b-modal>

  <validation-observer ref="VFormSubmit">
    <b-modal size="xl" title="Tanda Tangani Dokumen" @ok.prevent="doSubmitTransferIntra()" v-model="openTransferIntra">
      <div class="row">
        <div class="col-md-4">
          <b-form-group>
            <label>Nama & Tanda Tangan Pengirim <span class="text-danger">*</span></label>
            <b-form-input v-model="rowEdit.aranres_transfer_intra_pengirim"></b-form-input>
            <VValidate name="Nama & Tanda Tangan Pengirim" v-model="rowEdit.aranres_transfer_intra_pengirim" rules="required" />
          </b-form-group>
          <div class="form-group">
            <div class="signing-element" data-target-id="ttdPengirim">
              <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdPengirim" />
              <VValidate name="Tanda Tangan Pengirim" v-model="rowEdit.aranres_transfer_intra_pengirim_ttd" rules="required"></VValidate>
              <div class="mt-2">
                <button type="button" @click="undoTTD('ttdPengirim','aranres_transfer_intra_pengirim_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                  <b><i class="icon-cross3"></i></b>
                  <span>Clear</span>
                </button>
                <button @click.prevent="output('ttdPengirim','aranres_transfer_intra_pengirim_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                  <b><i class="icon-checkmark2"></i></b>
                  <span>Simpan</span>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">Nama & Tanda Tangan Penerima <span class="text-danger">*</span></label>
            <b-form-input v-model="rowEdit.aranres_transfer_intra_penerima"></b-form-input>
            <VValidate name="Nama & Tanda Tangan Penerima" v-model="rowEdit.aranres_transfer_intra_penerima" rules="required" />
          </div>
          <div class="form-group">
            <div class="signing-element" data-target-id="ttdPenerima">
              <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdPenerima" />
              <VValidate name="Tanda Tangan Penerima" v-model="rowEdit.aranres_transfer_intra_penerima_ttd" rules="required"></VValidate>
              <div class="mt-2">
                <button type="button" @click="undoTTD('ttdPenerima','aranres_transfer_intra_penerima_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                  <b><i class="icon-cross3"></i></b>
                  <span>Clear</span>
                </button>
                <button @click.prevent="output('ttdPenerima','aranres_transfer_intra_penerima_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                  <b><i class="icon-checkmark2"></i></b>
                  <span>Simpan</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="document-framer">
            <div class="df-content">
              <div style="position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 16px;">
                <div>
                  <table style="border-collapse: collapse;width: 100%;font-size: 16px;">
                    <tr>
                      <td>
                        <div style="float: left;width: 10%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                          <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                        </div>
                        <div style="text-transform: uppercase;float: left;width: 90%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                          <h1 style="margin:0;font-size: 21px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit
                            Harapan Magelang</h1>
                          <p style="margin:0;">Jl.P Senopati No 11, Magelang 56123</p>
                          <p style="margin:0;">Telp. (0293)-364033 s/d 364035</p>
                          <p style="margin:0;">Fax. (0293-364037)</p>
                        </div>
                      </td>
                      <td style="text-align: right;">
                        <span>Magelang, {{now}}</span>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2" style="padding: 16px 8px;text-align: center;">
                        <h2 style="font-size: 21px;text-transform: uppercase;padding-top: 16px;display: inline-block;margin-bottom: 4px;">
                          Transfer Intra Rumah Sakit</h2>
                        <div style="font-weight: normal;">Rawat Inap</div>
                      </th>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <template v-if="Object.keys(rowTransferIntra.rowIntraRanap||{}).length">
                        <template v-if="rowTransferIntra.cara_masuk == 'UGD'">
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="4">Situation</th>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Nama</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_fullname||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>No. RM</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_code||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>NIK</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_nik||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tanggal Lahir</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_dob | moment("DD MMMM YYYY")}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>DPJP</strong></div>
                                  <span>{{rowTransferIntra.rowReg.bu_full_name||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Jam Datang</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_jam_datang||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Diagnosa</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_diagnosa||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="4">
                                <div>
                                  <div><strong>Kondisi Saat Ini</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_kondisi_saat_ini||"-"}}</span>
                                </div>
                              </td>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Dari Ruang</strong></div>
                                  <span>UGD</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Ke Ruang</strong></div>
                                  <span>Rawat Inap</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Jam Pindah</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_jam_pindah||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="3">Background</th>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Intervensi yang Telah Dilakukan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_background_intervensi||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Riwayat Alergi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_riwayat_alergi||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Riwayat Pembedahan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_riwayat_pembedahan||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Tindakan Invasif</strong></div>
                                  <span v-for="(v,k) in (rowTransferIntra.rowIntraRanap.auira_tindakan_invasif||[])" :key="k">
                                    {{v}}</span>
                                  <span class="text-center" v-if="!(rowTransferIntra.rowIntraRanap.auira_tindakan_invasif||[]).length">Tidak ada data</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Obat-obatan yang diberikan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_obat_obatan_yang_diberikan||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Cairan Parametral yang diberikan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_cairan_parental_yang_diberikan||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="5">Assessment</th>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Kondisi Pasien Terkini</strong></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Skala Nyeri</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_skala_nyeri_value||0}}
                                    <span v-if="rowTransferIntra.rowIntraRanap.auira_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_skala_nyeri_value >= 1 && rowTransferIntra.rowIntraRanap.auira_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_skala_nyeri_value >= 4 && rowTransferIntra.rowIntraRanap.auira_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    <br />(Numeric Rating Scale)</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tingkat Kesadaran</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value||0}}
                                    <span v-if="rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value >= 12 && rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value >= 10 && rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value >= 7 && rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value >= 5 && rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value >= 4 && rowTransferIntra.rowIntraRanap.auira_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                    <br />(GCS)</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Risiko Jatuh</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_resiko_jatuh_value||0}}
                                    <span v-if="rowTransferIntra.rowIntraRanap.auira_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_resiko_jatuh_value >= 7 && rowTransferIntra.rowIntraRanap.auira_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.auira_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                    <br />
                                    
                                    <span v-if="rowTransferIntra.rowPasien.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                                    <span v-else-if="rowTransferIntra.rowPasien.ap_usia > 18 && rowTransferIntra.rowPasien.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                                    <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tekanan Darah</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_tekanan_darah_min||"-"}}/{{rowTransferIntra.rowIntraRanap.auira_tekanan_darah_max||"-"}} mmHG</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Nadi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_nadi||"-"}}x per menit</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Suhu</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_suhu||"-"}} &deg;C</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>RR</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_respiration||"-"}}x per menit</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>SpO2</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_spo2||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Pemeriksaan Lab</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_lab||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Pemeriksaan Radiologi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_radiologi||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="2">Recommendation</th>
                              <td style="padding: 2px 4px;" colspan="6">
                                <div>
                                  <div><strong>Tindakan yang perlu dilanjutkan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_tindakan||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Usulan Kolaborasi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.auira_usulan_kolab||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                        <template v-else-if="rowTransferIntra.cara_masuk == 'RAJAL'">
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="4">Situation</th>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Nama</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_fullname||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>No. RM</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_code||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>NIK</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_nik||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tanggal Lahir</strong></div>
                                  <span>{{rowTransferIntra.rowPasien.ap_dob | moment("DD MMMM YYYY")}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>DPJP</strong></div>
                                  <span>{{rowTransferIntra.rowReg.bu_full_name||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tanggal & Jam Datang</strong></div>
                                  <span>
                                  {{rowTransferIntra.rowIntraRanap.arira_tanggal_datang | moment("DD MMMM YYYY")}} {{rowTransferIntra.rowIntraRanap.arira_jam_datang||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Diagnosa</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_diagnosa||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="4">
                                <div>
                                  <div><strong>Kondisi Saat Ini</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_kondisi_saat_ini||"-"}}</span>
                                </div>
                              </td>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Dari Ruang</strong></div>
                                  <span>POLI</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Ke Ruang</strong></div>
                                  <span>Rawat Inap</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tanggal & Jam Pindah</strong></div>
                                  <span>
                                  {{rowTransferIntra.rowIntraRanap.arira_tanggal_pindah | moment("DD MMMM YYYY")}} {{rowTransferIntra.rowIntraRanap.arira_jam_pindah||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="3">Background</th>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Intervensi yang Telah Dilakukan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_background_intervensi||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Riwayat Alergi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_riwayat_alergi||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Riwayat Pembedahan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_riwayat_pembedahan||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Tindakan Invasif</strong></div>
                                  <span v-for="(v,k) in (rowTransferIntra.rowIntraRanap.arira_tindakan_invasif||[])" :key="k">
                                    {{v}}</span>
                                  <span class="text-center" v-if="!(rowTransferIntra.rowIntraRanap.arira_tindakan_invasif||[]).length">Tidak ada data</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Obat-obatan yang diberikan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_obat_obatan_yang_diberikan||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Cairan Parametral yang diberikan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_cairan_parental_yang_diberikan||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="5">Assessment</th>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Kondisi Pasien Terkini</strong></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Skala Nyeri</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_skala_nyeri_value||0}}
                                    <span v-if="rowTransferIntra.rowIntraRanap.arira_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_skala_nyeri_value >= 1 && rowTransferIntra.rowIntraRanap.arira_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan Ringan</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_skala_nyeri_value >= 4 && rowTransferIntra.rowIntraRanap.arira_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    <br />(Numeric Rating Scale)</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tingkat Kesadaran</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value||0}}
                                    <span v-if="rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value >= 12 && rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value >= 10 && rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value >= 7 && rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value >= 5 && rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value >= 4 && rowTransferIntra.rowIntraRanap.arira_tingkat_kesadaran_value <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                    <br />(GCS)</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Risiko Jatuh</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_resiko_jatuh_value||0}}
                                    <span v-if="rowTransferIntra.rowIntraRanap.arira_resiko_jatuh_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_resiko_jatuh_value >= 7 && rowTransferIntra.rowIntraRanap.arira_resiko_jatuh_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                                    <span v-else-if="rowTransferIntra.rowIntraRanap.arira_resiko_jatuh_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                                    <br />
                                    
                                    <span v-if="rowTransferIntra.rowPasien.ap_usia <= 18">(Kajian Humpty Dumpty)</span>
                                    <span v-else-if="rowTransferIntra.rowPasien.ap_usia > 18 && rowTransferIntra.rowPasien.ap_usia <= 60">(Kajian Morse Fall Scale)</span>
                                    <span v-else>(Kajian Skala Ontario Modified Stratify - Sydney Scoring)</span>

                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Tekanan Darah</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_tekanan_darah_min||"-"}}/{{rowTransferIntra.rowIntraRanap.arira_tekanan_darah_max||"-"}} mmHG</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Nadi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_nadi||"-"}}x per menit</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Suhu</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_suhu||"-"}} &deg;C</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>RR</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_respiration||"-"}}x per menit</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>SpO2</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_spo2||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                            
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Pemeriksaan Lab</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_lab||"-"}}</span>
                                </div>
                              </td>
                              <td style="padding: 2px 4px;" colspan="3">
                                <div>
                                  <div><strong>Pemeriksaan Radiologi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_radiologi||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <th style="text-align: left;vertical-align: top;" rowspan="2">Recommendation</th>
                              <td style="padding: 2px 4px;" colspan="6">
                                <div>
                                  <div><strong>Tindakan yang perlu dilanjutkan</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_tindakan||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 2px 4px;" colspan="2">
                                <div>
                                  <div><strong>Usulan Kolaborasi</strong></div>
                                  <span>{{rowTransferIntra.rowIntraRanap.arira_usulan_kolab||"-"}}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </template>
                    </tr>
                  </table>

                  <table style="width: 100%;font-size: 16px;margin-top:12px">
                    <tr>
                      <td style="text-align: center;">
                        <span>Pengirim</span>
                        <div>
                          <img :src="rowEdit.aranres_transfer_intra_pengirim_ttd||   'https://placeholder.com/400x160'" id="ttdPengirim" height="80" alt="">
                        </div>
                        {{rowEdit.aranres_transfer_intra_pengirim||"-"}}
                      </td>
                      <td style="text-align: center;">
                        <span>Penerima</span>
                        <div>
                          <img :src="rowEdit.aranres_transfer_intra_penerima_ttd||'https://placeholder.com/400x160'" id="ttdPenerima" height="80" alt="">
                        </div>
                        {{rowEdit.aranres_transfer_intra_penerima||"-"}}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </validation-observer>

  <b-modal size="lg" v-model="openRiwayat" title="Riwayat DPJP & Pindah Ruangan Pasien" ok-only ok-title="Tutup">
    <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0">
      <b-tab title="Riwayat DPJP">
        <div class="row mt-2">
          <div class="col-md-12 mb-2">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Dari Dokter</th>
                  <th>Ke Dokter</th>
                  <th>Tanggal Pindah</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>-</td>
                  <td>{{rowEdit.bu_full_name||"-"}}</td>
                  <td>{{rowEdit.aranr_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                </tr>
                <tr v-for="(v,k) in (logDPJP||[])" :key="k">
                  <td>{{v.dpjp_from||"-"}}</td>
                  <td>{{v.dpjp_to||"-"}}</td>
                  <td>{{v.aranlpd_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab title="Riwayat Pindah Ruang">
        <div class="row mt-2">
          <div class="col-md-12 mb-2">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Jenis Rawat Inap</th>
                  <th>Bangsal Asal</th>
                  <th>Bangsal Tujuan</th>                  
                  <th>Tanggal Pindah</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v,k) in (logPindahRuang||[])" :key="k">
                  <td>{{v.mranjr_name||"-"}}</td>
                  <td>{{getConfigDynamic(mRanapBangsal,v.aranpr_bangsal_asal)||"-"}}</td>
                  <td>{{getConfigDynamic(mRanapBangsal,v.aranpr_bangsal)||"-"}}</td>
                  <td>{{v.aranpr_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                </tr>
                <tr v-if="!(logPindahRuang||[]).length">
                  <td class="text-center" colspan="99">Tidak ada data</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>

  <b-modal v-model="isOpenModalHistory" :title="'Riwayat Rekam Medis'" size="xl" ok-only ok-title="Tutup">
      <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
          <b-tab title="UGD">
          <HistoryUGD :dataProps="dataUGD" :pasienId="rowEdit.aranr_ap_id" />
          </b-tab>
          <b-tab title="Rawat Jalan">
          <HistoryRawatJalan :dataProps="dataPoli" :pasienId="rowEdit.aranr_ap_id" />
          </b-tab>
      </b-tabs>
  </b-modal>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import ModalTTDBedahRanap from '../RanapSerahTerimaPasien/ModalTTDBedahRanap.vue'
import HistoryUGD from '@/components/Ranap/HistoryUGD.vue'
import HistoryRawatJalan from '@/components/Ranap/HistoryRawatJalan.vue'

const moment = require('moment')

// import Datepicker from 'vuejs-datepicker'

const _ = global._
import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components: {
    DateRangePicker,
    ModalTTDBedahRanap,
    HistoryUGD,
    HistoryRawatJalan
  },
  computed: {
    now() {
      return moment().format('DD MMM YYYY')
    },
    isDetail() {
      return this.$route.query.isDetail
    },
    isDoc() {
      return this.$route.query.isDoc
    },
    passToSub() {
      return {
        Config: this.Config,
        mrValidation: this.mrValidation,
        rowPasien: this.rowPasien,
        isShowBangsal: this.isShowBangsal,
        infoBangsal: this.infoBangsal,
        katalogBangsal: this.katalogBangsal
      }
    },
    showTabKajianPersalinan() {
      return !this.emptyKajianMandiri || !this.emptyLaporanNifas || !this.emptyCatatanPersalinan || !this.emptyLaporanPersalinan
    },
    emptyKajianMandiri() {
      return !Object.keys(this.kajianPersalinanData.kajianMandiri || {}).length
    },
    emptyCatatanPersalinan() {
      return !Object.keys(this.kajianPersalinanData.catatanPersalinan || {}).length
    },
    emptyLaporanPersalinan() {
      return !(this.kajianPersalinanData.laporanPersalinan || []).length
    },
  },
  data() {
    return {
      idKey: 'aranres_id',
      datePickerConfig: {
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dateRange: {
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
      },

      rowEdit: {},
      dataModal: {},
      rowPasien: {},
      rowAdditional: {},
      kajianPersalinanData: {},
      mRanapBangsal: [],
      mPPJP: [],
      openPerawatPelaksana: false,
      mDPJPUtama: [],
      loadingConsent: false,
      loadingTTDIntra: false,
      rowIC: {},
      detailInformConsent: {},
      openTransferIntra: false,
      rowTransferIntra: {},
      openRiwayat: false,

      logPindahRuang: [],
      logDPJP: [],
      dataModalCheck: {},
      
      isOpenModalHistory: false,
      dataPoli: {},
      dataUGD: {}
    }
  },
  mounted() {
    this.filter.status_jaminan = 'ALL'
    this.filter.cara_masuk = 'ALL'
    this.filter.ranap_bangsal = this.user.bu_bangsal_id
    this.filter.status_ranap = 2

    this.doConvertDate()
    this.apiGet()

    // if (this.isList) {
    //   this.apiGetResume()
    // }

    setTimeout(() => {
      this.setSlide()
    }, 1000)
  },
  methods: {
    openModalFormConsent(ar_id){
      this.$set(this.dataModal, 'forms', [])
      this.$bvModal.show('modal_inform_consent')

      this.loadingConsent = true

      Gen.apiRest("/get/" + this.modulePage + "/get-inform-consent-bbl", {
          params: {ar_id: ar_id}
      }).then(res => {
          this.loadingConsent = false
          this.$set(this.dataModal, 'forms', res.data.forms)
          this.$set(this.dataModal, 'ar_id', ar_id)
      }).catch(err => {
          console.log(err)
          this.loadingConsent = false
          this.$swal({
              icon: 'error',
              text: 'Terjadi suatu kesalahan saat pengambilan data'
          }).then(() => {
            this.$bvModal.hide('modal_inform_consent')
          })
      })
    },
    closeModalInformCosent(){
        this.tabIndex = 0
        this.$set(this, 'dataModal', {})
        this.$bvModal.hide('modal_inform_consent')
    },
    doSubmitConsent(){
        for(let i = 0; i < (this.dataModal.forms || []).length; i++){
            if(!this.dataModal.forms[i].aranic_is_pasien_setuju || !this.dataModal.forms[i].aranic_persetujuan_tindakan){
                this.tabIndex = i
                return
            }
        }

        this.$refs.VFormConsent.validate().then(success => {
            if(!success) return

            this.loadingOverlay = true

            Gen.apiRest("/do/RanapPasienPerawatanDPJP" , {
                data: { type: 'save-consent', payload: this.dataModal }
            }).then(res => {
                this.loadingOverlay = false
                this.$swal({
                    icon: 'success',
                    title: res.data.message
                }).then(() => {
                    this.closeModalInformCosent()
                    this.apiGet()
                })
            }).catch(err => {
                this.loadingOverlay = false
                this.$swal({
                    icon: 'error',
                    text: err.response.data.message || 'Terjadi suatu kesalahan!'
                })
            })
        })
    },
    isiFormPenyebabKematian(aranr_id){
      this.loadingOverlay = true

      Gen.apiRest("/get/RanapPasienPerawatan/get-formulir-lainnya-id" , {
        params: { aranr_id: aranr_id, slug: 'surat-keterangan-penyebab-kematian' }
      }).then(res => {
        this.$router.push({name: 'RanapFormulirLainnyaPerawat', params: {pageSlug: aranr_id, type: res.data.slug, id: res.data.id}})
        this.loadingOverlay = false
      }).catch(err => {
        this.loadingOverlay = false
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Terjadi suatu kesalahan!"
        })
      })
    },
    downloadKajianMandiri(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

       Gen.apiRest(
        "/do/" + "RanapKajianMandiriSC", {
          data: {
            id_registrasi: data.aranr_id,
            type: 'download-kajian-mandiri-sc'
          }
        },
        "POST"
      ).then(res => {
        this.$swal.close()
        const linkSource = res.data
        const downloadLink = document.createElement("a")
        downloadLink.href = linkSource
        downloadLink.download = `Kajian-Mandiri-${new Date().getTime()}.pdf`
        downloadLink.click()

        this.$swal.close()
        this.$bvModal.hide("previewDownload")
         
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },
    resize(){
      setTimeout(()=>{
        console.log('resize')
        $(".sign-container canvas").attr("width",339)
        $(".sign-container canvas").attr("height",160)
      },200)
    },
    changeTabRiwayat(tab) {
      this.tabRiwayat = tab
    },
    doOpenRiwayat(row) {
      this.rowEdit = row
      this.loadingOverlay = true
      let data = row
      data.type = "open-riwayat"
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.loadingOverlay = false
        this.openRiwayat = true

        this.logDPJP = resp.logDPJP
        this.logPindahRuang = resp.logPindahRuang
      })
    },
    doSubmitTransferIntra() {
      this.$refs['VFormSubmit'].validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          let data = this.rowEdit
          data.aranres_transfer_intra_temp = document.querySelector('.document-framer').innerHTML
          data.type = "submit-ttd-transfer-intra"
          Gen.apiRest(
            "/do/" + 'RanapPasienPerawatan', {
              data: data
            },
            "POST"
          ).then(res => {
            let resp = res.data
            this.loadingOverlay = false
            this.$swal({
              title: resp.message,
              icon: "success",
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.openTransferIntra = false
                this.apiGet()
              }
            })
          })
        }
      })
    },
    undoTTD(refs, field, payload = 'rowEdit') {
      this.$refs[refs].clearSignature()
      this[payload][field] = null
    },
    output(refs, field, payload = 'rowEdit') {
      let {
        isEmpty,
        data
      } = this.$refs[refs].saveSignature()
      if (!isEmpty) {
        this[payload][field] = data
      }
    },
    ttdTransferIntraUGDRANAP(v) {
      this.loadingOverlay = true
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'ttd-transfer-intra',
          id: v.aranr_id,
        }
      }, 'POST').then(res => {
        this.loadingOverlay = false
        this.rowTransferIntra = res.data
        this.openTransferIntra = true
        this.rowEdit = v

        setTimeout(() => {
          $(".sign-container canvas").attr("width", 400)
          $(".sign-container canvas").attr("height", 160)
          if (this.rowEdit.aranres_transfer_intra_pengirim_ttd) {
            this.$refs['ttdPengirim'].fromDataURL(this.rowEdit.aranres_transfer_intra_pengirim_ttd)
          }
          if (this.rowEdit.aranres_transfer_intra_penerima_ttd) {
            this.$refs['ttdPenerima'].fromDataURL(this.rowEdit.aranres_transfer_intra_penerima_ttd)
          }
        }, 500)
      })
    },

    closeModalTTDBedahRanap(){
        this.$set(this, 'dataModal', {})
        this.$bvModal.hide('ttd_bedah_ranap')
    },
    TTDBedahRanap(val){
        this.$set(this.dataModal, 'arantbr_id', val.arantbr_id)
        this.$set(this.dataModal, 'arano_id', val.arano_id)
        this.$bvModal.show('ttd_bedah_ranap')
    },
    successTTDBedahRanap(){
        this.$bvModal.hide('list_ttd_intra')
        this.closeModalTTDBedahRanap()
        this.apiGet()
    },
    checkTTDIntra(row){
        this.loadingTTDIntra = true
        this.$bvModal.show('list_ttd_intra')

        Gen.apiRest('/get/RanapSerahTerimaPasien/check-need-ttd', {
            params: {
                aranr_id: row.aranr_id,
            }
        }).then(res => {
            this.loadingTTDIntra = false
            this.dataModal = row
            this.dataModalCheck.show_ttd_intra_ranap = res.data.show_ttd_intra_ranap
            this.dataModalCheck.transferBedah = res.data.transferBedah

            setTimeout(() => {
            $(".sign-container canvas").attr("width", 400)
            $(".sign-container canvas").attr("height", 160)
            }, 200)
        })
    },

    ttdTransferIntra(v) {
      this.$bvModal.show('ttd_transfer_intra')
      this.loadingTTDIntra = true
      Gen.apiRest('/get/RanapSerahTerimaPasien/form-ttd-regist', {
        params: {
          aranr_id: v.aranr_id,
        }
      }).then(res => {
        this.loadingTTDIntra = false
        this.dataModal = res.data.row

        setTimeout(() => {
          $(".sign-container canvas").attr("width", 400)
          $(".sign-container canvas").attr("height", 160)
        }, 200)
      })
    },
    closeModalTTD(){
      this.$set(this, 'dataModal', {})
      this.$bvModal.hide('ttd_transfer_intra')
    },
    doSubmitTTD(){
      this.output('ttd1','artipr_ttd_1', 'dataModal')
      this.output('ttd2','artipr_ttd_2', 'dataModal')

      if(!this.dataModal.artipr_ttd_1 || !this.dataModal.artipr_ttd_2){
        return this.$swal({
          icon: 'error',
          title: 'Mohon Isi Tanda Tangan'
        })
      }

      this.$refs.VFormTTD.validate().then(success => {
        if(!success) return
        
        this.loadingOverlay = true

        Gen.apiRest('/do/RanapSerahTerimaPasien', {
          data: {
            type: 'submit-ttd',
            ...this.dataModal
          }
        }).then(res => {
          this.loadingOverlay = false
          this.$swal({
            icon: 'success',
            title: res.data.message,
          }).then(() => {
            this.closeModalTTD()
            this.apiGet()
          })
        }).catch(err => {
          this.loadingOverlay = false
          this.$swal({
            icon: 'error',
            message: err.response.data.message || 'Terjadi suatu kesalahan!'
          })
        })
      })
    },
    openModalInformConsentDetail(v) {
      this.$set(this, 'detailInformConsent', v)
      this.$bvModal.show('modal_inform_consent_detail')
    },
    uploadDok(v, k = '') {
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'update-data-ic',
          id: v.aranic_id,
          aranic_file: v.aranic_file,
          aranic_nama_pj: v.aranic_nama_pj,
          aranic_address_pj: v.aranic_address_pj,
          aranic_usia_pj: v.aranic_usia_pj,
        }
      }, 'POST').then(res => {
        if (k) {
          this.informConcent[k]['aranic_status'] = 'DONE'
        }
      })
    },
    unduhtemplateIC(v) {
      this.$refs['VFormDocInformConsent'].validate().then(success => {
        if (success) {
          this.uploadDok(v)
          setTimeout(() => {
            this.$bvModal.hide('unduh_doc_inform_consent')
            let data = {
              exptype: 'xlsx',
              type: "export",
              id: v.aranic_id,
              regID: v.aranic_aranr_id,
              dokter_pelaksana: this.dataModal.dokter_pelaksana,
            }
            let self = this
            self.loadingOverlay = true
            $.ajax({
              type: "POST",
              url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsentRanap'}?token=IXs1029102asoaksoas102901290`,
              data: data,
              cache: false,
              xhrFields: {
                responseType: 'blob'
              },
              success: data => {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `${'Inform-consent'}-${v.ap_fullname}-${moment().format("YYYY-MM-DD")}-${v.aranr_reg_code}.pdf`
                link.click()
              },
              fail: data => {
                self.loadingOverlay = false
                alert('Not downloaded')
              }
            })
          }, 2000)

        }
      })
    },
    uploadDokStatus(v, k = '') {
      Gen.apiRest('/do/' + this.modulePage, {
        data: {
          type: 'update-data-ic',
          id: v.aranic_id,
          aranic_file: v.aranic_file,
          aranic_nama_pj: v.aranic_nama_pj,
          aranic_address_pj: v.aranic_address_pj,
          aranic_usia_pj: v.aranic_usia_pj,
          aranic_status: 'DONE'
        }
      }, 'POST').then(res => {
        this.informConcent[k]['aranic_status'] = 'DONE'
      })
    },
    openModalUnduhInformConsent(v) {
      this.$set(this, 'rowIC', v)
      
      if(this.rowIC.aranic_persetujuan_tindakan == 'P'){
        this.unduhtemplateIC(this.rowIC)
      }else{
        this.$bvModal.show('unduh_doc_inform_consent')
      }

    },
    openModalInformConsentTTD(ar_id,row) {
      this.$set(this.dataModal, 'forms', [])
      this.$set(this.dataModal, 'dokter_pelaksana', this.dataModal.dokter_pelaksana||row.bu_full_name)
      this.$bvModal.show('ttd_modal_inform_consent')

      this.loadingConsent = true

      Gen.apiRest("/get/" + this.modulePage + "/get-inform-consent-bbl", {
        params: {
          ar_id: ar_id
        }
      }).then(res => {
        this.loadingConsent = false
        this.$set(this.dataModal, 'forms', res.data.forms)
        this.$set(this.dataModal, 'ar_id', ar_id)
      }).catch(err => {
        console.log(err)
        this.loadingConsent = false
        this.$swal({
          icon: 'error',
          text: 'Terjadi suatu kesalahan saat pengambilan data'
        }).then(() => {
          this.$bvModal.hide('modal_inform_consent')
        })
      })

    },
    openModalKajianPersalinan(data) {    
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian-persalinan",
            regId: data.aranr_id
          }
        },
        "POST"
      ).then(res => {
        this.$bvModal.show('kajianPersalinan')
        this.$set(this, 'dataModal', { ...data, ...res.data.data })
      })
    },
    toMonitoring(regID) {
      this.$router.push({
        name: 'RanapMonitoring',
        params: {
          pageSlug: regID
        }
      }).catch(() => {})
    },
    submitPerawatPelaksana() {
      this.$refs['VFormSubmitPerawatPelaksana'].validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          })
        } else {
          this.loadingOverlay = true
          let data = this.rowEdit
          data.type = 'assign-perawat-pelaksana'

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          ).then(res => {
            let resp = res.data
            this.loadingOverlay = false
            this.$swal({
              title: resp.message,
              icon: "success",
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.openPerawatPelaksana = false
                this.apiGet()
              }
            })
          })
        }
      })
    },
    tugasKanPPJP(v) {
      this.rowEdit = v
      let data = this.rowEdit
      data.type = 'get-data-alergi-diagnosa'
      this.loadingOverlay = true

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.openPerawatPelaksana = true
        this.loadingOverlay = false
        this.rowEdit.alergi = resp.alergi
        this.rowEdit.diagnosa = resp.diagnosa

        this.rowEdit.aranr_perawat_pelaksana = (this.rowEdit.aranr_perawat_pelaksana||[])
        let perawatPelaksanaRemovenull = this.rowEdit.aranr_perawat_pelaksana.filter(function(value) {
          return value != null
        })
        this.rowEdit.aranr_perawat_pelaksana = perawatPelaksanaRemovenull
        
        this.mPPJP = resp.mPPJP

      })

    },
    setSlide() {
      const responsiveTable = document.querySelector('.table-responsive')
      if (responsiveTable) {
        const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
        const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
        if (responsiveTableScroll) {
          // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
          Array.from(responsiveTableScrollActions).forEach(action => {
            action.addEventListener('click', e => {
              const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100

              responsiveTable.scroll({
                left: scrollAmount,
                behavior: 'smooth'
              })
            })
          })
        }
      }
    },
    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    apiGetResume() {
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      Gen.apiRest(
        "/get/" + this.modulePage + '/resume', {
          params: Object.assign({}, paramsQuery || {})
        }
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 1000),
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    validationTime(regDate) {
      if (moment().format('YYYY-MM-DD') >= this.to24Hours(regDate)) {
        return true
      } else {
        return false
      }
    },
    to24Hours(date) {
      return moment(date).add(24, 'hours').format('YYYY-MM-DD')
    },
    initKajianAwal(row) {
      this.createKajianAwal(row)
    },
    createKajianAwal(row) {
      this.loadingOverlay = true
      let data = {
        idPasien: row.aranr_ap_id,
        regId: row.aranr_id,
        dokterId: row.aranr_dokter_id,
        dokterRaber: row.aranr_dokter_raber_id,
        jenisRawatInap: row.aranr_jenis_ranap
      }
      data.type = 'init-kajian-awal'
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.loadingOverlay = false
        this.$router.push({
          name: resp.menuKajian,
          params: {
            pageSlug: row.ap_id,
            rmNo: resp.aranrm_id
          },
          query: {
            regId: row.aranr_id
          }
        }).catch(() => {})
      })
    },
    lihatKajian(row) {
      this.$router.push({
        name: this.toMenuKajianAwal(row.aranr_kajian_awal_type),
        params: {
          pageSlug: row.ap_id,
          rmNo: row.aranrm_id
        },
        query: {
          regId: row.aranr_id
        }
      }).catch(() => {})
    },

    doOpenRiwayatKajian(row){
      this.rowEdit = row
      this.loadingOverlay = true
      Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
          data: {
              type: "get-riwayat-poli",
              id: this.rowEdit.aranr_ap_id
          }
          },
          "POST"
      ).then(res => {
          let resp = res.data
          this.dataPoli = resp.dataPoli
      })

      Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
          data: {
              type: "get-riwayat-ugd",
              id: this.rowEdit.aranr_ap_id
          }
          },
          "POST"
      ).then(res => {
          let resp = res.data
          this.loadingOverlay = false
          this.isOpenModalHistory = true
          this.dataUGD = resp.dataUGD
      })
    },

  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      this.apiGet()

      // if (this.isList) {
      //   this.apiGetResume()
      // }

      setTimeout(() => {
        if (this.isList) {
          this.setSlide()
        }
      }, 1000)
    },
    '$route.params.pageSlug': function () {
      this.$set(this, 'filter', {})
    },
    'openPerawatPelaksana': function (v) {
      if (!v) {
        this.apiGet()
      }
    }
  }
}
</script>

<style scoped>
.cta-float{
  position: absolute;
  top: -1em;
  right: 0;
  z-index: 99;
}
</style>
